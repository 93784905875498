import Glide from '@glidejs/glide'

if (document.getElementsByClassName('glide').length > 0) {
    new Glide('.glide', {
        type: 'carousel',
        startAt: 0,
        perView: 4,
        breakpoints: {
            992: { perView: 2 },
            768: { perView: 1 },
        },
    }).mount()
}